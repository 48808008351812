<template>
  <div class="index-main">
    <div class="main-left">
      <a-card style="height:21%">
        <div slot="title"><a-icon type="unordered-list"  style="margin-right:4px" /><span style="font-weight:bold">入库管理</span></div>
        <div class="entryManage">
          <div v-for="(item, index) in entryManageData" :key="index">
            <div>{{item.name}}</div>
            <div class="entryNum">
              <span
                :class="{
                  'entryManageData1': item.type === 1,
                  'entryManageData2': item.type === 2,
                  'entryManageData3': item.type === 3,
                  'entryManageData4': item.type === 4
                }"
              >{{item.num}}</span>部
            </div>
          </div>
        </div>
      </a-card>
      <a-card style="margin: 10px 0; height: 21%">
        <div slot="title"><a-icon type="unordered-list"  style="margin-right:4px" /><span style="font-weight:bold">标签关联追踪</span></div>
        <div class="labelAssociateWatch">
          <div v-for="(item, index) in assoData" :key="index">
            <div>{{item.name}}</div>
            <div class="assoNum"><span :class="{
              'assoData1': item.type === 0,
              'assoData2': item.type === 1,
            }">{{item.num}}</span><span>部</span></div>
          </div>
        </div>
      </a-card>
      <a-card style="height:58%" class="a-card-table-design">
        <div slot="title"><a-icon type="unordered-list"  style="margin-right:4px" /><span style="font-weight:bold">最新意见反馈</span></div>
        <a-table :columns="columns" :data-source="tableData" :pagination="false">
        </a-table>
      </a-card>
    </div>
    <div class="main-right">
      <a-card style="height: 100%">
        <div slot="title"><a-icon type="unordered-list"  style="margin-right:4px" /><span style="font-weight:bold">综合统计</span></div>
        <div class="commonTop">
          <div v-for="(item, index) in efficientData" :key="index">
            <div :class="{
              'efficientData1': item.type === '1',
              'efficientData2': item.type === '2',
              'efficientData3': item.type === '3',
              'efficientData4': item.type === '4',
              'efficientData5': item.type === '5',
            }">{{item.num}}</div>
            <div>{{item.name}}</div>
          </div>
        </div>
        <div>
          <div id="echartContainer" style="width: 100%; height: 500px"></div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: '序号',
    customRender: (text, record, index) => `${index + 1}`,
    width: 60,
    align: 'center'
  },
  {
    title: '法律名称',
    dataIndex: 'lawName',
    width: 170,
    ellipsis: true
  },
  {
    title: '反馈内容',
    dataIndex: 'feedbackContent',
    width: 170,
    ellipsis: true
  },
  {
    title: '反馈时间',
    dataIndex: 'feedbackTime',
    width: 100,
    ellipsis: true
  },
  {
    title: '反馈人',
    dataIndex: 'userName',
    width: 80,
    ellipsis: true
  }
]

// var echarts = require('echarts')

export default {
  data() {
    return {
      columns,
      homeData: [],
      tableData: [],
      entryManageData: [],
      assoData: [],
      efficientData: {
        value: '',
        name: ''
      },
      lawLevelData: [],
      standSize: document.body.clientWidth
    }
  },
  created() {
    this.getData()
  },
  mounted() {
    console.log('this.initEcharts()this.initEcharts()', this.initEcharts())
  },
  methods: {
    getData() {
      this.$http('post_fzzyk_getHomeData').then(res => {
        if (res.code === 200) {
          this.homeData = res.data
          this.tableData = res.data.最新反馈意见
          // 入库管理数据enum替换
          // const map1 = new Map()
          // const arrList1 = ['待提交', '取消发布', '待发布', '已发布']
          // arrList1.forEach((item, index) => {
          //   map1.set(index + 1, item)
          // })
          // res.data.入库管理.forEach(item => {
          //   item.type = map1.get(item.type)
          //   return item.type
          // })
          this.entryManageData = res.data.入库管理
          // 标签关联
          // const map2 = new Map()
          // const arrList2 = ['未关联', '已关联']
          // arrList2.forEach((item, index) => {
          //   map2.set(index, item)
          // })
          // res.data.标签关联追踪.forEach(item => {
          //   item.type = map2.get(item.type)
          //   return item.type
          // })
          this.assoData = res.data.标签关联追踪
          // 时效性
          // const map3 = new Map()
          // const arrList3 = ['现行有效', '已修改', '已失效', '尚未生效', '已废止']
          // arrList3.forEach((item, index) => {
          //   map3.set(String(index + 1), item)
          // })
          // res.data.时效性.forEach(item => {
          //   item.type = map3.get(item.type)
          //   return item.type
          // })
          this.efficientData = res.data.时效性
          // 效力级别
          const map4 = new Map()
          const arrList4 = ['宪法法律', '行政法规', '地方性法规', '部门规章', '地方政府规章', '司法解释', '规范性文件', '中央文件', '监察法规']
          const arrList5 = ['01', '04', '07', '13', '15', '12', '94', '93', '91']
          arrList4.forEach((item, index) => {
            map4.set(arrList5[index], item)
          })
          // console.log('arrList4arrList4arrList4', arrList4)
          res.data.效力级别.forEach(item => {
            item.type = map4.get(item.type)
            this.lawLevelData.push({
              name: item.type,
              value: item.num
            })
          })
          // console.log('lawLevelDatalawLevelData', this.lawLevelData)
        }
      })
    },
    initEcharts() {
      // 基于准备好的dom，初始化echarts实例
      // var myChart = this.$echarts.init(document.getElementById('main'))
      setTimeout(() => {
        var myChart = this.$echarts.init(document.getElementById('echartContainer'))
        // 绘制图表
        var option
        option = {
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {c}部'
          },
          legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 10,
            top: 160,
            bottom: 20,
            data: this.lawLevelData
            // formatter: function (name) {
            //   let num = 0
            //   this.lawLevelData.forEach(item => {
            //     if (item.name === name) {
            //       num = item.value
            //     }
            //   })
            //   let arr = ['{a |' + name + '}'}, '']
            // }
          },
          series: [
            {
              type: 'pie',
              startAngle: 0,
              minAngle: 4,
              // avoidLabelOverlap: true,
              radius: ['30%', '50%'],
              avoidLabelOverlap: false,
              hoverAnimation: false,
              center: ['40%', '60%'],
              label: {
                normal: {
                  show: true,
                  position: 'outside',
                  formatter: '{b}:{c}部',
                  textStyle: {
                    fontWeight: 'normal',
                    fontSize: 10
                  }
                }
              },
              labelLine: {
                normal: {
                  show: true
                }
              },
              data: this.lawLevelData
            }
          ]
        }
        myChart.setOption(option)
      }, 1000)
    }
  }
}
</script>

<style lang='less' scoped>
/deep/.ant-table-thead > tr > th{
  background: transparent;
}
.index-main {
  width: 100%;
  height: calc(100vh - 10px);
  margin: 10px;
  display: flex;
  justify-content: space-between;
  // background: red;
  overflow: hidden;
  .main-left {
    height: 100%;
    width: 50%;
    margin-right: 10px;
    // background: pink;
    .entryManage {
      display: flex;
      justify-content: space-around;
      .entryNum{
        span{
          display: inline-block;
          win-width: 66px;
          font-size: 22px;
          margin-right: 5px;
        }
        .entryManageData1 {
          color:#71b262;
        }
        .entryManageData2 {
          color:#f87171;
        }
        .entryManageData3 {
          color:#ecac60;
        }
        .entryManageData4 {
          color:#6288b2;
        }
      }
    }
    .labelAssociateWatch {
      display: flex;
      justify-content: space-around;
      div{
        // display: flex;
        // justify-content: space-around;
        .assoNum{
          // padding-left: 10px;
          span:first-of-type{
            // display: inline-block;
            win-width: 66px;
            font-size: 22px;
            margin-right: 5px;
          }
          .assoData1 {
            color: #71b262;
          }
          .assoData2 {
            color: #6288b2;
          }
        }
      }
    }
    .a-card-table-design {
      padding: 0 !important
    }
  }
  .main-right {
    height: 100vh;
    width: 50%;
    // background: blueviolet;
    .commonTop {
      width: 100%;
      display: flex;
      justify-content: space-around;
      > div {
        text-align: center;
        width: calc(100% / 7);
        height: 90px;
        // background: pink;
        box-shadow: 0px 0px 10px 0px rgba(218, 218, 218, 0.521);
        div:first-child{
          margin: 14px 0;
          font-size: 18px;
          font-weight: bold;
        }
      }
      .efficientData1{
        color:#6288b2;
      }
      .efficientData2{
        color:#ecac60;
      }
      .efficientData3{
        color:#f87171;
      }
      .efficientData4{
        color:#71b262;
      }
      .efficientData5{
        color:#000;
      }
    }
  }
}
</style>

