var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index-main"},[_c('div',{staticClass:"main-left"},[_c('a-card',{staticStyle:{"height":"21%"}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('a-icon',{staticStyle:{"margin-right":"4px"},attrs:{"type":"unordered-list"}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("入库管理")])],1),_c('div',{staticClass:"entryManage"},_vm._l((_vm.entryManageData),function(item,index){return _c('div',{key:index},[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"entryNum"},[_c('span',{class:{
                'entryManageData1': item.type === 1,
                'entryManageData2': item.type === 2,
                'entryManageData3': item.type === 3,
                'entryManageData4': item.type === 4
              }},[_vm._v(_vm._s(item.num))]),_vm._v("部 ")])])}),0)]),_c('a-card',{staticStyle:{"margin":"10px 0","height":"21%"}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('a-icon',{staticStyle:{"margin-right":"4px"},attrs:{"type":"unordered-list"}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("标签关联追踪")])],1),_c('div',{staticClass:"labelAssociateWatch"},_vm._l((_vm.assoData),function(item,index){return _c('div',{key:index},[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"assoNum"},[_c('span',{class:{
            'assoData1': item.type === 0,
            'assoData2': item.type === 1,
          }},[_vm._v(_vm._s(item.num))]),_c('span',[_vm._v("部")])])])}),0)]),_c('a-card',{staticClass:"a-card-table-design",staticStyle:{"height":"58%"}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('a-icon',{staticStyle:{"margin-right":"4px"},attrs:{"type":"unordered-list"}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("最新意见反馈")])],1),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.tableData,"pagination":false}})],1)],1),_c('div',{staticClass:"main-right"},[_c('a-card',{staticStyle:{"height":"100%"}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('a-icon',{staticStyle:{"margin-right":"4px"},attrs:{"type":"unordered-list"}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("综合统计")])],1),_c('div',{staticClass:"commonTop"},_vm._l((_vm.efficientData),function(item,index){return _c('div',{key:index},[_c('div',{class:{
            'efficientData1': item.type === '1',
            'efficientData2': item.type === '2',
            'efficientData3': item.type === '3',
            'efficientData4': item.type === '4',
            'efficientData5': item.type === '5',
          }},[_vm._v(_vm._s(item.num))]),_c('div',[_vm._v(_vm._s(item.name))])])}),0),_c('div',[_c('div',{staticStyle:{"width":"100%","height":"500px"},attrs:{"id":"echartContainer"}})])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }